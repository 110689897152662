import React from 'react';
import PropTypes from 'prop-types';

import ImgSrcset from '../img-srcset';
import WYSIWYG from '../wysiwyg';
import Button from '../button';

// Component styles.
import styles from './news-detail.module.scss';

const NewsDetail = ({
  srcSet,
  alt,
  title,
  summaryCheck,
  summary,
  date,
  tag,
  parentTag,
  body,
  cta,
  type
}) => (
  <div className={styles.newsDetailContainer}>
    <div>
      {srcSet && (
        <ImgSrcset
          alt={alt}
          srcs={srcSet}
          className={styles.newsDetailImage}
          sizes={'(max-width: 576px) 98vw, (max-width: 795px) 80vw, 1080px'}
          height="1074"
          width="604"
        />
      )}
    </div>
    <div className={styles.newsDetailContent}>
      <p className={styles.newsDetailEyebrow}>
        {type === 'News' && (
          <span>{parentTag ? `${parentTag}: ${tag}` : parentTag}</span>
        )}
        {type === 'Place' && (
          <span>{parentTag ? `${parentTag}: ${tag}` : parentTag}</span>
        )}
      </p>
      <div className={styles.newsDetailHeadings}>
        <h1 className={styles.newsTitle}>{title}</h1>
        <p className={styles.newsDate}>{date}</p>
      </div>

      <WYSIWYG
        className={summaryCheck ? styles.newsSummary : styles.newsBody}
        body={summaryCheck ? summary : body}
      />
      {cta && (
        <div className={styles.newsCtas}>
          <Button
            aria-label={`Read more about ${title}`}
            className={styles.cardButton}
            to={cta}
            borderStyle="round"
            color="blue"
            type="button"
          >
            More Information
          </Button>
        </div>
      )}
    </div>
  </div>
);

NewsDetail.propTypes = {
  srcSet: PropTypes.array,
  alt: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  body: PropTypes.string,
  cta: PropTypes.object,
  tag: PropTypes.string,
  parentTag: PropTypes.string,
  type: PropTypes.string,
  summary: PropTypes.string,
  summaryCheck: PropTypes.bool
};

export default NewsDetail;
