/**
 * News Node template.
 *
 * Each "news" node in Drupal that has the destination set to "Inside Sandy Springs"
 * will use this template to generate a corresponding page with Gatsby.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import InteriorLayout from '../components/interior-layout/interior-layout';
import NewsDetail from '../components/news-detail/news-detail';

const NewsNode = ({ data }) => {
  const ratio16x9links = data?.nodeNews?.srcSet?.ratio16x9?.links ?? {};
  const ratio16x9 = ratio16x9links
    ? Object.keys(ratio16x9links).map((value) => ratio16x9links[value].href)
    : [];

  // Depending on how much content there is, show more or fewer sidebar cards.
  let totalLength = 0;
  let sidebarDepth = 4;

  totalLength += data?.nodeNews?.title.length;
  totalLength += data?.nodeNews?.body.processed.length;

  if (totalLength > 950 && totalLength < 4000) {
    sidebarDepth = 3;
  } else if (totalLength <= 950) {
    sidebarDepth = 2;
  }

  return (
    <Layout>
      <InteriorLayout sidebarDepth={sidebarDepth}>
        <NewsDetail
          srcSet={ratio16x9}
          alt={data?.nodeNews?.srcSet?.alt}
          title={data?.nodeNews?.title}
          type={data?.nodeNews?.relationships?.type?.name}
          date={data?.nodeNews?.field_date.value}
          body={data?.nodeNews?.body.processed}
          cta={data?.nodeNews?.cta?.uri}
          summaryCheck={data?.nodeNews?.field_summary_only}
          summary={data?.nodeNews?.body.summary}
          tag={data?.nodeNews?.relationships?.field_tags?.[0]?.name}
          parentTag={
            data?.nodeNews?.relationships?.field_tags?.[0]?.relationships
              ?.parent[0]?.name
          }
        />
      </InteriorLayout>
    </Layout>
  );
};

NewsNode.propTypes = {
  data: PropTypes.object
};

export const query = graphql`
  query NewsNodeQuery($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    nodeNews(path: { alias: { eq: $slug } }) {
      title
      field_summary_only
      drupal_id
      path {
        alias
      }
      body {
        processed
        summary
      }
      cta: field_webpage {
        title
        uri
      }
      metatag: metatag {
        attributes {
          name
          content
        }
      }
      srcSet: field_image {
        ...newsSrcSet16x9Fragment
      }
      relationships {
        type: node_type {
          name
        }
        field_tags {
          name
          relationships {
            parent {
              name
            }
          }
        }
      }
      field_date {
        value(formatString: "MMMM D, YYYY")
      }
    }
  }
`;

export default NewsNode;
